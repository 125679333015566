<template>
  <AppLayout>
    <!-- <router-view :key="$route.fullPath"/> -->
    <router-view />
  </AppLayout>
</template>

<script>
// @ is an alias to /src
import AppLayout from "@/layouts/AppLayout";
import { useStore } from "vuex";
import { reactive, computed, watch } from "vue";
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";
// import fitvids from 'fitvids'

export default {
  name: "App",
  components: {
    AppLayout,
  },

  setup() {
    const route = useRoute();

    var siteData = reactive({
      seo_title: `Semineu pe lemne la preturi mici, seminee PeFoc rustice, moderne`,
      seo_description: `La PeFoc ai o gama diversificata de seminee moderne, termoseminee, sobe pe lemne, focare seminee, usi de semineu la preturi accesibile. Vezi oferta noastra.`,
      seo_keywords: `termosemineu, semineu centrala, cu serpentina, focar, pe colt, calorifere, lemne, pardosea, incalzire, toata casa`,
      seo_image: require("@/assets/images/header-slider.jpg"),
      robots: "index, folow",
      canonical_url: window.location.origin + route.fullPath,
    });
    const store = useStore();

    // console.log(siteData);

    useHead({
      // Can be static or computed
      title: computed(() => siteData.seo_title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.seo_description),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.seo_keywords),
        },
        {
          name: `robots`,
          content: computed(() => siteData.robots),
        },
        {
          property: `og:title`,
          content: computed(() => siteData.seo_title),
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: `og:url`,
          content: window.location.origin + route.fullPath,
        },
        {
          property: "og:image",
          content: computed(() => siteData.seo_image),
        },
      ],
      link: [
        {
          rel: `canonical`,
          href: computed(() => {
            return siteData.canonical_url
              ? window.location.origin + siteData.canonical_url
              : window.location.origin + route.fullPath;
          }),
        },
      ],
    });
    const updateSiteData = async () => {
      // console.log('--->> Seo_params_state is Updated');
      siteData.seo_description = store.getters["seo/seo_description"];
      siteData.seo_title = store.getters["seo/seo_title"];
      siteData.seo_keywords = store.getters["seo/seo_keywords"];
      siteData.canonical_url = store.getters["seo/canonical_url"];
      siteData.seo_image = store.getters["seo/seo_image"];
    };

    watch(updateSiteData);

    return {
      updateSiteData,
      siteData,
    };
  },

  mounted() {
    this.$store.dispatch("blog/getLatestPosts");
    this.$store.dispatch("shop/getCart").then((data) => {
      // Redirect if cart empty
      if(this.$route.name == "Checkout" && data.length < 1) {
        this.$router.push({ name: "Cart" });
      }
      
      this.$store.dispatch("shop/actCartIsLoaded", true);
    });
    this.$store.dispatch("shop/getCurrency");
  },
};
</script>

<style>
@import "./assets/css/common.css";
@import "./assets/css/style.css";
</style>

<style lang="scss">
@import "./assets/scss/style.scss";
</style>

<style>
:root {
    /** Change button primary color to black **/
    --cc-bg: #c0050f ;
    --cc-btn-primary-bg: #c0050f !important;
    --cc-btn-primary-border-color: #fff !important;
    --cc-btn-primary-hover-bg: #c0050f !important;
    --cc-btn-primary-hover-border-color: #fff !important;
    --cc-btn-primary-hover-color: #fff !important;
    --cc-btn-border-radius: 10px;

    /** Also make toggles the same color as the button **/
    --cc-toggle-on-bg: var(--cc-btn-primary-bg);
}

#cc_div{ 
  --cc-btn-primary-text: #fff; 
  color: #fff !important; 
  font-family: Helvetica,Calibri,Arial,sans-serif !important;
  font-size: 15px !important; 
}

#c-bns button:first-child{
  color: #fff !important; 
}

.c-bn{ 
  border-radius: 0% !important; 
  border: 1.5px solid !important; 
  border-color: white !important; 
}

#c-txt{ 
  font-size: 15px !important;
}

#cm{ 
  border-radius: 0% !important; 
}
</style>